<template>
    <div>
        <ts-page-title
            :title="$t('agencyProfile.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('agencyProfile.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-items-center"
									:class="$whois.admin() ? 'tw-justify-between': 'tw-justify-end'"
								>
                    <div
											v-if="$whois.admin()"
										>
                        <a-button type="primary" @click="addNew">
                            {{ $t("addNew") }}
                        </a-button>
                    </div>
                    <div class="tw-space-x-3">
											<a-radio-group v-model="isActive" @change="fetchData()">
												<a-radio-button :value="1">
													{{ $t('agencyProfile.active') }}
												</a-radio-button>
												<a-radio-button :value="0">
													{{ $t('agencyProfile.inactive') }}
												</a-radio-button>

											</a-radio-group>
                        <a-tooltip :title="$t('agencyProfile.search')">
                            <a-input-search
                                v-model="search"
                                :placeholder="$t('agencyProfile.search')"
                                style="width: 200px"
                            />
                        </a-tooltip>
                    </div>
                </div>
            </ts-panel-wrapper>
            <ts-table
                :columns="columns"
                :records="resources"
                :loading="loading"
            >
                <template v-slot="{ record }">
                    <td>{{ record.agency_code }}</td>
                    <td>{{ record.agency_name }}</td>
                    <td>{{ record.contact_person }}</td>
                    <td>
                        <div class="tw-flex">
                            <p class="tw-mb-0">{{ record.phone1 }}</p>
                            /
                            <p class="tw-mb-0">{{ record.phone2 }}</p>
                        </div>
                    </td>
                    <td>
                        <div class="tw-flex">
                            <p class="tw-mb-0">{{ record.contract_start_date }}</p>
                            -
                            <p class="tw-mb-0">{{ record.contract_end_date }}</p>
                        </div>
                    </td>
										<td class="tw-text-center">
											<span class="badge bg-primary" v-if="record.is_active">Yes</span>
											<span class="badge bg-secondary" v-else>No</span>
											
										</td>
                    <td class="text-center tw-space-x-3">
                        <a
                            href="#"
                            v-tooltip="$t('edit')"
                            class="text-primary mr-2"
                            @click.prevent="edit(record)"
                        >
                            <i class="fas fa-edit"></i>
                        </a>
                    </td>
                </template>
            </ts-table>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
        </ts-panel>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { debounce } from "lodash";

export default {
    name: "agencyIndex",
    data() {
        return {
            loading: false
        };
    },
    computed: {
        ...mapState("agency/agencyProfile", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.agency.agencyProfile.search;
            },
            set(newValue) {
                this.$store.commit("agency/agencyProfile/SET_SEARCH", newValue);
                this.$store.commit("agency/agencyProfile/RESET_CURRENT_PAGE");
            }
        },
        isActive: {
            get() {
                return this.$store.state.agency.agencyProfile.isActive;
            },
            set(newValue) {
                this.$store.commit("agency/agencyProfile/SET_ACTIVE", newValue);
            }
        },
        columns() {
            return [
                { name: this.$t("agencyProfile.agencyCode"), sort: '' },
                { name: this.$t("agencyProfile.agencyName") },
                { name: this.$t("agencyProfile.contactPerson") },
                { name: this.$t("agencyProfile.phoneNumber") },
                { name: this.$t("agencyProfile.contractDate") },
                { name: this.$t("agencyProfile.active"), style: 'text-align:center' },
                {
                    name: this.$t("actions"),
                    style: "text-align:center; width: 1px;"
                }
            ];
        }
    },
    methods: {
        addNew() {
            this.$router.push({ name: "agency-profile-create" });
        },
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("agency/agencyProfile/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        edit(record) {
            this.$store.commit("agency/agencyProfile/SET_EDIT_DATA", record);
            this.$router.push({
                name: "agency-profile-edit",
                params: { id: record.agency_id }
            });
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("agency/agencyProfile/RESET_STATE");
        next();
    }
};
</script>
