var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('agencyProfile.pageTitle'),"breadcrumb":[
                { text: _vm.$t('home'), href: '/' },
                {
                    text: _vm.$t('agencyProfile.pageTitle'),
                    active: true
                }
            ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"tw-flex tw-items-center",class:_vm.$whois.admin() ? 'tw-justify-between': 'tw-justify-end'},[(_vm.$whois.admin())?_c('div',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.addNew}},[_vm._v(" "+_vm._s(_vm.$t("addNew"))+" ")])],1):_vm._e(),_c('div',{staticClass:"tw-space-x-3"},[_c('a-radio-group',{on:{"change":function($event){return _vm.fetchData()}},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('a-radio-button',{attrs:{"value":1}},[_vm._v(" "+_vm._s(_vm.$t('agencyProfile.active'))+" ")]),_c('a-radio-button',{attrs:{"value":0}},[_vm._v(" "+_vm._s(_vm.$t('agencyProfile.inactive'))+" ")])],1),_c('a-tooltip',{attrs:{"title":_vm.$t('agencyProfile.search')}},[_c('a-input-search',{staticStyle:{"width":"200px"},attrs:{"placeholder":_vm.$t('agencyProfile.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)])]),_c('ts-table',{attrs:{"columns":_vm.columns,"records":_vm.resources,"loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var record = ref.record;
return [_c('td',[_vm._v(_vm._s(record.agency_code))]),_c('td',[_vm._v(_vm._s(record.agency_name))]),_c('td',[_vm._v(_vm._s(record.contact_person))]),_c('td',[_c('div',{staticClass:"tw-flex"},[_c('p',{staticClass:"tw-mb-0"},[_vm._v(_vm._s(record.phone1))]),_vm._v(" / "),_c('p',{staticClass:"tw-mb-0"},[_vm._v(_vm._s(record.phone2))])])]),_c('td',[_c('div',{staticClass:"tw-flex"},[_c('p',{staticClass:"tw-mb-0"},[_vm._v(_vm._s(record.contract_start_date))]),_vm._v(" - "),_c('p',{staticClass:"tw-mb-0"},[_vm._v(_vm._s(record.contract_end_date))])])]),_c('td',{staticClass:"tw-text-center"},[(record.is_active)?_c('span',{staticClass:"badge bg-primary"},[_vm._v("Yes")]):_c('span',{staticClass:"badge bg-secondary"},[_vm._v("No")])]),_c('td',{staticClass:"text-center tw-space-x-3"},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('edit')),expression:"$t('edit')"}],staticClass:"text-primary mr-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.edit(record)}}},[_c('i',{staticClass:"fas fa-edit"})])])]}}])}),_c('div',{staticClass:"d-flex justify-content-end tw-p-4"},[_c('ts-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }